<template>
  <div>
    <aad-title>Sessions</aad-title>

    <aad-loader
      v-if="allSessions == undefined || allSessions.length == 0"
    ></aad-loader>

    <div v-else class="row session-container">
      <!-- Printing Disclaimer -->

      <div class="d-none print-show">
        <h2 v-if="currentMeeting && currentMeeting.Name">
          {{ currentMeeting.Name }}
          <span v-if="currentMeeting.Country == 'USA'"
            >- {{ currentMeeting.City }}, {{ currentMeeting.State }}</span
          >
        </h2>
        <p>
          <strong>Disclaimer:</strong> This printout was generated at
          {{ currentDate }}. Session information can change before and during
          the live event. Please visit
          <a :href="currentUrl">{{ currentUrl }}</a> for the most up-to-date
          information.
        </p>
      </div>

      <div class="col-xl-4 p-1 session-sidebar">
        <a
          class="btn btn-primary-solid d-xl-none mb-4 w-100 print-hide"
          href="#"
          @click="toggleFilters"
          >{{ mobileFiltersVisible ? "Hide Filters" : "View Filters" }}
        </a>

        <!-- Search Section -->
        <div class="d-xl-block" :class="{ 'd-none': !mobileFiltersVisible }">
          <session-search-filters-search-term></session-search-filters-search-term>

          <session-search-filters-favorites></session-search-filters-favorites>

          <session-search-filters-whats-on-now></session-search-filters-whats-on-now>

          <session-search-filters-current> </session-search-filters-current>

          <!-- Filters -->
          <div v-for="(filter, key) in sessionSearchFilters" :key="key">
            <session-search-filters-list
              v-if="filter.isFilterList"
              :filter-key="key"
              :filter="filter"
              :items="getComputedByName(filter.getter)"
            ></session-search-filters-list>
          </div>
          <hr class="d-xl-none my-5" />
        </div>
      </div>

      <!-- Session List -->
      <div class="col-xl-8 p-1 session-list">
        <div class="d-lg-flex justify-content-between align-items-center">
          <h2 class="px-2">
            {{ filteredSessions.length }} Sessions Found
            <span v-if="anySessionSearchFilters">With Current Filters</span>
          </h2>

          <a
            href="#"
            @click="printSessions"
            class="btn btn-primary-outline mr-2 print-hide"
          >
            <aad-icon name="print" class="print-icon btn-icon mr-2"></aad-icon>
            Print Sessions</a
          >
        </div>
        <div v-if="filteredSessions && filteredSessions.length > 0">
          <div v-for="session in filteredSessions" :key="session.id">
            <session-search-session-card
              :session="session"
              v-if="!session.SessionCode.startsWith('T') || canViewTestSessions"
            ></session-search-session-card>
          </div>
        </div>
        <div v-else>
          <div class="px-4">No sessions match these filters.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import SessionSearchFiltersCurrent from "@/components/SessionSearchFiltersCurrent.vue";
import SessionSearchFiltersList from "@/components/SessionSearchFiltersList.vue";
import SessionSearchFiltersSearchTerm from "@/components/SessionSearchFiltersSearchTerm.vue";
import SessionSearchSessionCard from "@/components/SessionSearchSessionCard.vue";
import SessionSearchFiltersFavorites from "../components/SessionSearchFiltersFavorites.vue";
import SessionSearchFiltersWhatsOnNow from "../components/SessionSearchFiltersWhatsOnNow.vue";
import Analytics from "@/mixins/analytics.js";

export default {
  mounted() {
    this.getSessions();

    this.mobileFiltersVisible = this.anySessionSearchFilters;

    // Allow filtering on load based on querystring parameters
    // This allows edu to send out pre-filtered links
    this.allFilterKeys.forEach((filter) => {
      if (this.$route.query[filter]) {
        this.setSessionSearchFilterByKey({
          key: filter,
          value: this.$route.query[filter],
        });
      }
    });
  },
  metaInfo: {
    title: "Sessions",
  },
  components: {
    SessionSearchFiltersCurrent,
    SessionSearchFiltersList,
    SessionSearchFiltersSearchTerm,
    SessionSearchSessionCard,
    SessionSearchFiltersFavorites,
    SessionSearchFiltersWhatsOnNow,
  },
  data() {
    return {
      mobileFiltersVisible: false,
    };
  },
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    printSessions: function () {
      // Log to analytics first
      Analytics.logLinkClick("print_sessions", "/sessions/print", "sessions");

      // Sometimes the print returns mobile styles so we need to update the screen,
      // Then let Vue update the DOM reactively (setTimeout to prevent race condition
      // from the printing) and then when done disable it.
      this.mobileFiltersVisible = true;
      setTimeout(() => {
        const isSafari =
          /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === "[object SafariRemoteNotification]";
          })(
            !window["safari"] ||
              (typeof safari !== "undefined" &&
                window["safari"].pushNotification)
          );

        // Safari is outdated and hates using non depreciated methods
        // It won't work with window.print() so we have to detect it
        // and then make changes. Ugh.
        if (isSafari) {
          document.execCommand("print", false, null);
        } else {
          window.print();
        }

        this.mobileFiltersVisible = false;
      }, 500);
    },
    getComputedByName(computedName) {
      return this[computedName];
    },
    toggleFilters() {
      this.mobileFiltersVisible = !this.mobileFiltersVisible;
    },
  },
  computed: {
    ...mapGetters([
      "anySessionSearchFilters",
      "categoriesInSessions",
      "datesOfSessions",
      "categoriesInSessions",
      "typesInSessions",
      "filteredSessions",
      "allSessions",
      "canViewTestSessions",
      "currentMeeting",
      "queryStringSearchFilters",
      "allFilterKeys",
    ]),
    ...mapState({
      sessionSearchFilters: (state) => state.sessionSearch.sessionSearchFilters,
    }),
    currentUrl() {
      return window.location.href;
    },
    currentDate() {
      return new Date().toLocaleString();
    },
  },
  watch: {
    // Update the url as filters are chosen
    queryStringSearchFilters(newValue) {
      this.$router.replace({ query: newValue });
    },
  },
};
</script>

<style>
.print-icon {
  stroke-width: 2px;
}
</style>
