<template>
  <div class="print-hide">
    <input
      class="form-control mb-4 search-box"
      type="text"
      placeholder="Search..."
      :value="searchTerm"
      @input="updateSearchTerm"
      @blur="logSearchTerm()"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Analytics from "@/mixins/analytics";

export default {
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    updateSearchTerm(e) {
      this.setSessionSearchFilterByKey({
        key: "searchTerm",
        value: e.target.value,
      });
    },
    logSearchTerm() {
      Analytics.logEvent("aad_search_term", {
        search_term: this.searchTerm,
        search_type: "sessions",
      });
    },
  },
  computed: {
    ...mapState({
      searchTerm: (state) =>
        state.sessionSearch.sessionSearchFilters["searchTerm"].value,
    }),
  },
};
</script>

<style scoped></style>
