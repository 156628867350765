<template>
  <div class="">
    <a
      href="#"
      class="btn btn-primary-outline w-100 mb-2"
      @click="toggleWhatsOnNow"
      v-if="!whatsOnNowValue && isMeetingRunning"
    >
      <aad-icon name="hurry-circle-filled" class="alarm btn-icon"></aad-icon>
      What's On Now?</a
    >
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import Analytics from "@/mixins/analytics";

export default {
  methods: {
    ...mapMutations(["setSessionSearchFilterByKey"]),
    toggleWhatsOnNow() {
      Analytics.logEvent("aad_filter_click", {
        filter_value: "whats_on_now",
        filter_type: "whats_on_now",
      });

      this.setSessionSearchFilterByKey({
        key: "whatsOnNow",
        value: !this.whatsOnNowValue,
      });
    },
  },
  computed: {
    ...mapState({
      whatsOnNowValue: (state) =>
        state.sessionSearch.sessionSearchFilters["whatsOnNow"].value,
    }),
    ...mapGetters(["isMeetingRunning"]),
  },
};
</script>

<style scoped>
.alarm {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #03821b;
}
</style>
