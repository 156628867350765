<template>
  <div class="meeting-app-info-block">
    <div class="meeting-app-info-header row">
      <div class="meeting-app-info-date col-md-9 d-flex align-items-center">
        <a
          @click="toggleFavorite"
          class="favorite-toggle-link"
          v-if="isLoggedIn"
        >
          <aad-icon
            :name="isFavorited ? 'star-filled' : 'star-thick'"
            class="favorite-star"
            :class="isFavorited ? 'star-filled' : 'star-thick'"
          ></aad-icon>
        </a>

        <h3>
          <session-time-string
            :session="session"
            type="start-date"
          ></session-time-string>
        </h3>
      </div>
      <div class="meeting-app-info-time col-md-3">
        <h3>
          <session-time-string
            :session="session"
            type="start-time"
          ></session-time-string>
        </h3>
      </div>
    </div>
    <div class="meeting-app-info-body">
      <div class="row mb-2">
        <router-link
          :to="{ name: 'SessionDetails', params: { id: session.id } }"
          class="col-lg-8 text-link-light-bg pt-2"
          id="searchSessionTitle"
          @click.native="logSessionClick()"
          @click.native.middle="logSessionClick()"
        >
          <h2>{{ session.SessionCode + " - " + session.Name }}</h2>
        </router-link>

        <div class="col-lg-4 session-tags">
          <span
            v-for="category in session.Categories"
            class="tag-pill d-inline-block tag-pill-gray m-1"
            :key="category"
            >{{ category }}</span
          >
        </div>
      </div>
      <div class="row">
        <!-- Only display description and speakers on print -->
        <div class="col-12 mb-3 d-none print-show">
          <div class="mt-0" v-if="speakersByRole('Director')">
            <strong>Director:</strong> {{ speakersByRole("Director") }}
          </div>
          <div class="mt-0" v-if="speakersByRole('Co-Director')">
            <strong
              >Co-Director<span
                v-if="speakersByRole('Co-Director').includes(';')"
                >s</span
              >:</strong
            >
            {{ speakersByRole("Co-Director") }}
          </div>
          <div class="mt-0" v-if="speakersByRole('Speaker')">
            <strong
              >Speaker<span v-if="speakersByRole('Speaker').includes(';')"
                >s</span
              >:</strong
            >
            {{ speakersByRole("Speaker") }}
          </div>
        </div>

        <p class="col-12 d-none print-show">
          <strong>Description:</strong> {{ session.Description }}
        </p>

        <p class="col-12">
          <aad-icon name="calendar" class="meeting-app-info-svg"></aad-icon>
          <session-time-string :session="session"></session-time-string>
        </p>
        <p class="col-12">
          <aad-icon name="place" class="meeting-app-info-svg"></aad-icon>
          {{ session.LocationName || "Location To Be Announced" }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import SessionTimeString from "@/components/SessionTimeString.vue";
import AadIcon from "./AadIcon.vue";
import { mapGetters } from "vuex";
import Analytics from "@/mixins/analytics";

export default {
  props: ["session"],
  components: { SessionTimeString, AadIcon },
  methods: {
    toggleFavorite() {
      this.$store.dispatch("toggleFavorite", this.session.id);
    },
    speakersByRole: function (role) {
      return this.session.Speakers.filter((speaker) => speaker.Role == role)
        .map((speaker) => speaker.DisplayName)
        .join("; ");
    },
    logSessionClick() {
      Analytics.logEvent("aad_session_click", {
        search_term: this.getSearchTerm,
        aad_session_id: this.session.id,
        session_code: this.session.SessionCode,
        applied_filters: this.getActiveFiltersAsString,
        screen_origin: "sessions",
      });
    },
  },
  computed: {
    ...mapGetters([
      "isSessionInFavorites",
      "isLoggedIn",
      "getActiveFiltersAsString",
      "getSearchTerm",
    ]),
    isFavorited: function () {
      return this.isSessionInFavorites(this.session.id);
    },
  },
};
</script>

<style scoped>
.favorite-star {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.star-filled {
  color: #f7d131;
}

.star-filled:hover {
  color: #f7e79f;
}

.star-thick {
  color: #fff;
}

.star-thick:hover {
  color: #f7e79f;
}
</style>
