<template>
  <div
    class="meeting-app-info-block my-2"
    id="current-session-filter-block"
    v-if="anySessionSearchFilters"
  >
    <div class="meeting-app-info-header row">
      <h4>Current Filters</h4>
    </div>
    <div class="meeting-app-info-body">
      <div
        class="row meeting-app-info-item print-hide"
        id="clear-all-session-filters"
      >
        <a href="#" @click="clearAllSessionSearchFilters">
          <aad-icon name="close-circle"></aad-icon>
          Clear All Filters
        </a>
      </div>
      <div v-for="(filter, key) in sessionSearchFilters" :key="filter.key">
        <div class="row" v-if="filter.value">
          <div class="col-lg-12">
            <span class="font-weight-bold"> {{ filter.display }}: </span>
            <a
              :href="filter.value ? '#' : false"
              @click="clearSessionSearchFilterByKey(key)"
            >
              <aad-icon
                name="close-circle"
                class="print-hide"
                v-if="filter.value"
              ></aad-icon>
              {{ filter.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";

export default {
  props: ["filters"],
  methods: {
    ...mapMutations([
      "clearAllSessionSearchFilters",
      "clearSessionSearchFilterByKey",
    ]),
  },
  computed: {
    ...mapGetters(["anySessionSearchFilters"]),
    ...mapState({
      sessionSearchFilters: (state) => state.sessionSearch.sessionSearchFilters,
    }),
  },
};
</script>

<style scoped>
.meeting-app-info-body svg {
  width: 20px;
  height: 20px;
}

.meeting-app-info-body {
  word-break: break-word;
}
</style>
