var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    !_vm.sessionSearchFilters[_vm.filterKey].value &&
    _vm.items != undefined &&
    Object.keys(_vm.items).length !== 0
  )?_c('div',{staticClass:"meeting-app-info-block meeting-app-block-collapse print-hide",class:{ 'meeting-app-info-open': _vm.openOnMobile }},[_c('div',{staticClass:"meeting-app-info-header row",on:{"click":_vm.toggleOpenOnMobile}},[_c('h4',[_vm._v(_vm._s(_vm.filter.display))]),_c('aad-icon',{staticClass:"collapse-icon",attrs:{"name":"caret-right"}})],1),_c('div',{staticClass:"meeting-app-info-body"},_vm._l((_vm.items),function(count,key){return _c('div',{key:key,staticClass:"row meeting-app-info-item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.setFilters({
            key: _vm.filterKey,
            value: key,
          })}}},[_vm._v(" "+_vm._s(key)+" "),_c('span',{staticClass:"tag-pill tag-pill-gray ml-2"},[_vm._v(" "+_vm._s(count)+" ")])])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }